export const environment = {
  production: true,
  preview: false,
  siteURL: 'https://www.ahome.com.tw',
  firebase: {
    apiKey: 'AIzaSyDRHwoX0iyPPbtVhqQz0juL0bOPgB3hKM8',
    authDomain: 'orange-house-website.firebaseapp.com',
    databaseURL: 'https://orange-house-website.firebaseio.com',
    projectId: 'orange-house-website',
    storageBucket: 'orange-house-website.appspot.com',
    messagingSenderId: '435287867759',
    appId: '1:435287867759:web:6acef5192e1d17ed8a289a',
  },
  geocodingKey: 'AIzaSyChtFKZtsJD9SddgrHC798277jCPXghbkE',
  fbPageId: '100083525340824',
  fbAppId: '101115609238001',
  awoo: {
    url: 'https://api.awoo.org/nununi',
    id: 2204912200,
    token: 'cfe8206976cd4d9ccd4314c5f4aebbb3a1e6dbe0',
  },
};
